import React, { useEffect, useState } from "react";
import "./IntroScreen.css";

const IntroScreen: React.FC = () => {
  const [displayText, setDisplayText] = useState<string>("");
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  const introText = "Hearty welcome to Luntovskyy-Bingo!";

  useEffect(() => {
    const intervalId: NodeJS.Timer = setInterval(() => {
      if (currentIndex < introText.length) {
        setDisplayText((prevText) => prevText + introText[currentIndex]);
        setCurrentIndex(lastIndex => lastIndex + 1);
      } else {
        clearInterval(intervalId);
      }
    }, 100);

    return () => {
      clearInterval(intervalId);
    };
  }, [currentIndex]);

  return (
    <div className="intro-screen">
      <h1>{displayText}</h1>
    </div>
  );
};

export default IntroScreen;
