import { useState } from "react";
import { sayings2 } from "./components/Data2";
import { GameTable } from "./components/GameTable";
import "./App.css";
import App from './App'; // Assuming App is in the same directory

const App2: React.FC = () => {
  const [showApp, setShowApp] = useState(false);

  const navigateToApp = () => {
    setShowApp(true);
  };

  if (showApp) {
    return <App />;
  }

  return (
    <div className="App">
      <header className="App-header">
        <h1 className="App-heading">Hauptmann-Bingo</h1>
        <GameTable sayings={sayings2()} />

        <hr style={{ borderColor: '#ffbfa1', borderWidth: '2px', width: '90%' }} />

        <h2 className="Headline-Hauptmann">Zurück in den Orbit!</h2>
        <button className="App2-Button" onClick={navigateToApp}>Zum Satelliten-Bingo</button>
      </header>
    </div>
  );
};

export default App2;