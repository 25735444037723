import _ from "lodash";

export const sayings2 = (): string[][] => {
  const _sayings: string[] = [
    "Haben sie Fragen?",
    "Ähm",
    "Äh",
    "Ehh"
  ];

  const rndSayings: string[] = _.shuffle(_sayings).slice();
  const cols: number = 5;
  const sayingsTable: string[][] = Array(5)
    .fill("")
    .map((_, index) => rndSayings.slice(index * cols, (index + 1) * cols));

  return sayingsTable;
};
