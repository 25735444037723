import _ from "lodash";

export const sayings = (): string[][] => {
  const _sayings: string[] = [
    "Satellit",
    "GEO", 
    "LEO",
    "MEO",
    "Starlink",
    "Elon Musk",
    "International Spacial Station",
    "Seine Bücher",
    "SpaceX",
    "Datenrate",
    "Footprint",
    "Orbit",
    "ISDN",
    "ALOHA",
    "CSMA/CD",
    "Jemand muss vorlesen",
    "Jemand muss an die Tafel",
    "Hemd gerissen",
    "Kreide geht Kaputt",
    "Ups",
    "Zu spät",
    "Mikrofon Akku leer",
    "Springt in Folien wild hin und her",
    "Sagt einen falschen Namen",
    "Tritt auf Kreide",
    "Rechnet Laufzeit an der Tafel", 
    "Sein eigenes Programm",
    "wissenschaftlicher Taschenrechner",
    "Verpasst die Pause",
    "L-A-N",
    "5x Ähhhh in einem Satz",
    "Marco schüttelt mit dem Kopf",
    "Entschuldigung",
    "Statt abwischen, dazwischen quetschen",
    "Ei Treipel I",
    "Sachen doppelt aufschreiben/erklären",
    "Abwechelndes Vorlesen"
  ];

  const rndSayings: string[] = _.shuffle(_sayings).slice();
  const cols: number = 5;
  const sayingsTable: string[][] = Array(5)
    .fill("")
    .map((_, index) => rndSayings.slice(index * cols, (index + 1) * cols));

  return sayingsTable;
};
