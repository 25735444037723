import { useEffect, useState } from "react";
import { sayings } from "./components/Data";
import { sayings2 } from "./components/Data2";
import { GameTable } from "./components/GameTable";
import "./App.css";
import IntroScreen from "./components/IntroScreen";
import App2 from './App2'; // Assuming App2 is in the same directory

const App: React.FC = () => {
  const [showIntro, setShowIntro] = useState(true);
  const [showApp2, setShowApp2] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowIntro(false);
    }, 4500);

    return () => clearTimeout(timeoutId);
  }, []);

  const navigateToApp2 = () => {
    setShowApp2(true);
  };

  if (showApp2) {
    return <App2 />;
  }

  return (
    <div className="App">
      {showIntro ? (
        <IntroScreen />
      ) : (
        <>
          <header className="App-header">
            <h1 className="App-heading">Satelliten-Bingo</h1>
            <GameTable sayings={sayings()} />

            <hr style={{ borderColor: '#ffbfa1', borderWidth: '2px', width: '90%' }} />

            <h2 className="Headline-Hauptmann">EXCLUSIV HIER</h2>
            <button className="App2-Button" onClick={navigateToApp2}>Zum Hauptmann-Bingo</button>
          </header>
        </>
      )}
    </div>
  );
};

export default App;